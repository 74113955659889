import React from 'react'
import Layout from '@/layout/Layout'
import { Link } from 'gatsby'

const ErrorPg = () => (
	<Layout>
		<section className="section">
			<h1 className="h0">Page not found...</h1>
			<p>Return to the <Link className="link" to="/">homepage</Link>.</p>
		</section>
	</Layout>
)

export default ErrorPg
